import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import useFetchData from "./useFetchData";

function BlogPostTopicIdeas() {
  const [formData, setFormData] = useState({
    name: '',
    detail: '',
    audience: '',
    toneOfVoice: ''
  });
  const [apiResponse, setApiResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [user, setUser] = useState(null);
  const { data, loadingHook, errorHook } = useFetchData(`${process.env.REACT_APP_API_URL}/user_crud/retrieve_voices`);


  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
    }
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form validation
    if (!formData.name || !formData.detail || !formData.audience || !formData.toneOfVoice) {
      setError('All fields are required.');
      return;
    }

    setLoading(true);
    setApiResponse(null); // Clear previous response
    setError(null); // Clear previous error

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/charli/charli/Blog_topic`, 
        {
          params: {
            name: formData.name,
            detail: formData.detail,
            audience: formData.audience,
            tone: formData.toneOfVoice,
            // email: user?.email // Adding email parameter from user state
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`, // Set Bearer token in Authorization header
          }
        }
      );
      
      setApiResponse(response.data);
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred while fetching data.');
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (!apiResponse) return;

    setSaving(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/charli/save`, 
        {
          name: formData.name,
          detail: formData.detail,
          audience: formData.audience,
          tone: formData.tone,
          generatedResponse: apiResponse.response,
          // email: user?.email, // Adding email parameter from user state
          cardType: 'Blog Post Topic Ideas'
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`, // Set Bearer token in Authorization header
            "Content-Type": "application/json"
          }
        }
      );
      

      if (response.status === 200) {
        alert('Response saved successfully!');
      } else {
        console.error('Save Response:', response);
        alert('Failed to save the response.');
      }
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      alert('An error occurred while saving the response.');
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="form-container">
      <div className="left-panel">
        <form style={{ maxWidth: '400px', margin: 'auto' }} onSubmit={handleSubmit}>
          <div style={{ marginBottom: '15px' }}>
            <span className="form-title">Blog Post Topic Ideas</span>
          </div>

          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="name">Brand Name:</label>
            <input
              id="name"
              type="text"
              placeholder="Eco Warrior"
              style={{ width: '100%', padding: '9px' }}
              onChange={handleChange}
              value={formData.name}
            />
          </div>

          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="detail">Product Description:</label>
            <textarea
              id="detail"
              placeholder="Eco-friendly products for a sustainable lifestyle, including reusable bags, water bottles, and home cleaning solutions."
              style={{ width: '100%', minHeight: '100px', padding: '5px' }}
              onChange={handleChange}
              value={formData.detail}
            ></textarea>
          </div>

          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="audience">Audience:</label>
            <textarea
              id="audience"
              placeholder="Eco-conscious consumers, Sustainability advocates, Homeowners"
              style={{ width: '100%', minHeight: '100px', padding: '5px' }}
              onChange={handleChange}
              value={formData.audience}
            ></textarea>
          </div>

          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="toneOfVoice" >Tone of Voice:</label>
            <select 
              id="toneOfVoice"
             className="dropdown-style"
              onChange={handleChange}
              value={formData.toneOfVoice}
            >
              <option value="">Select a tone...</option>
              {!loadingHook && data?.voices?.map((voice) => (
                <option key={voice.id} value={voice.name}>
                  {voice.name}
                </option>
              ))}
            </select>
            {loadingHook && <p>Loading voices...</p>}
            {errorHook && <p>Error loading voices: {errorHook.message}</p>}
          </div>
          <button type="submit" id='submit-btn' disabled={loading}>
            {loading ? 'Generating...' : 'Generate'}
          </button>
        </form>
      </div>

      <div className="right-panel">
        <div className="response-window">
          {loading && <p>Loading...</p>}
          {error && <p>Error: {error}</p>}
          {apiResponse && (
            <div>
              <ComponentWithApiResponse data={apiResponse.response} />
              <button onClick={handleSave} id='submit-btn' disabled={saving}>
                {saving ? 'Saving...' : 'Save Response'}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function ComponentWithApiResponse({ data }) {
  return (
    <div>
      <ReactMarkdown>{data}</ReactMarkdown>
    </div>
  );
}

export default BlogPostTopicIdeas;
