// CreateCard.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./CreateCard.scss";

const suggestions = [
  { text: "Amazon Product", link: "/cards/amazon-product" },
  { text: "Amazon Product Feature", link: "/cards/amazon-p-feature" },
  { text: "Blog Post Conclusion Paragraph", link: "cards/blog-post" },
  { text: "Blog Post Intro Paragraph", link: "/cards/blog-post-intro" },
  { text: "Blog Post Outline", link: "/cards/blog-post-outline" },
  { text: "Blog Post Topic Ideas", link: "/cards/blog-post-topic-ideas" },
  { text: "Business or Product Name", link: "/cards/business-product-name" },
  { text: "Commands", link: "/cards/commands" },
  { text: "Creative Story", link: "/cards/company-bio" },
  { text: "Email Subject Lines", link: "/cards/email-subject-lines" },
  { text: "Company Bio", link: "//cards/company-bio" },
  { text: "Content Improver", link: "/ContentImprover" },
  { text: "Facebook Ad", link: "/cards/facebook-ads" },
  { text: "Job Description", link: "/cards/job-description" },
  { text: "LinkedIn Topic Ideas", link: "/cards/linkedin-topic-ideas" },
];

const cards = [
  {
    id: 1,
    title: "Amazon Product Description",
    description: "Create compelling product descriptions for Amazon listings.",
    link: "/cards/amazon-product",
  },
  {
    id: 2,
    title: "Amazon Product Features",
    description: "Create compelling product features for Amazon listings.",
    link: "/cards/amazon-p-feature",
  },
  // {
  //   id: 3,
  //   title: "Blog Post Conclusion Paragraph",
  //   description: "Create compelling blog post conclusion paragraphs.",
  //   link: "/BlogPost",
  // },
  // {
  //   id: 4,
  //   title: "Blog Post Intro Paragraph",
  //   description: "Create compelling blog post intro paragraphs.",
  //   link: "/BlogPostIntro",
  // },
];

function CreateCard() {
  const [task, setTask] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  const handleInputChange = (e) => {
    const input = e.target.value;
    setTask(input);

    if (input.trim() === "") {
      setFilteredSuggestions([]);
    } else {
      const filtered = suggestions.filter((suggestion) =>
        suggestion.text.toLowerCase().startsWith(input.toLowerCase())
      );
      setFilteredSuggestions(filtered);
    }
  };

  return (
    <div
      className=""
      style={{
        width: "100%",
        height: "100%",
        background:
          "linear-gradient(to top, rgb(249, 168, 212), rgb(216, 180, 254), rgb(129, 140, 248))",
        borderRadius: "25px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "start",
        padding: "10%",
      }}
    >
      <h1
        style={{
          width: "100%",
          textAlign: "center",
          color: "white",
          fontSize: "24px",
          fontWeight: "bold",
          marginBottom: "20px",
        }}
      >
        What do you want to create?
      </h1>
      <div
        className=""
        style={{
          width: "100%",
          borderRadius: "10px",
          background: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "0px 4px 0 0px",
          position: "relative",
          height:'40px'
        }}
      >
        <div
          className=""
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
            
          }}
        >
          <input
          
            type="text"
            name=""
            id=""
            placeholder="Specify a writing task"
            style={{
              background: "transparent",
              padding: "0px 10px",
              border: "none",
              fontSize: "1.2rem !important",
              boxShadow:'none',
              height:'20px'

            }}
            value={task}
            onChange={handleInputChange}
          />
          {/* Button */}
          <div
            style={{
              width: "125px",
              height: "60%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "15px",
              background:
                "linear-gradient(to right, rgb(249, 168, 212), rgb(216, 180, 254), rgb(129, 140, 248))",
              padding: "5px 20px 5px 20px",
              borderRadius: "5px",
              color: "white",
            }}
          >
            {/* <img src="/images/star.svg" alt="stars" /> */}
            <div
              style={{
                width: "100%",
                textAlign: "center",
                padding:'10px',
                
              }}
            >
             <span> Start</span>
            </div>
          </div>
        </div>
        {task && (
          <div
            className=""
            style={{
              position: "absolute",
              top: "50px",
              left: "20px",
              width: "90%",
              margin: "0 auto",
              padding: "10px",
              borderRadius: "10px",
              background: "white",
              zIndex: 1000000000,
              border: "1px solid rgba(0, 0, 0, 0.3)",
            }}
          >
            {filteredSuggestions.length > 0
              ? filteredSuggestions.map((suggestion, index) => (
                  <Link
                    key={index}
                    to={suggestion.link}
                    style={{
                      textDecoration: "none",
                      color: "black",
                      display: "block",
                      padding: "0px",
                      fontSize: "10px",
                      background: "transparent",
                    }}
                  >
                    {suggestion.text}
                  </Link>
                ))
              : null}
          </div>
        )}
      </div>
      <div
        className=""
        style={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "25px",
          marginTop: "45px",
        }}
      >
        {cards.map((card) => (
          <Link
            to={card.link}
            key={card.id}
            className="card-layout"
            style={{
              width: "100%",
              // gridColumn: "span 1",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              background: "white",
              borderRadius: "10px",
              padding: "20px",
              textDecoration:'none'
            }}
          >
            <h4
              style={{
                textDecoration:'none',
                width: "100%",
                textAlign: "left",
                fontSize:'12px'
              }}
            >
              {card.title}
            </h4>
            <span className="card-description"
              style={{
                width: "100%",
                textAlign: "left",
                margin: "0 !important",
                color: 'black',
                fontSize:'10px'
              }}
            >
              {card.description}
            </span>
          </Link>
        ))}
      </div>
    </div>
    // <div className="createCard">
    //   <div className="card">
    //     <div className="row">
    //       <div className="col-xl-6 col-lg-10 col-md-10 mx-auto">
    //         <h3 style={{textAlign:'center',marginTop:'10px'}}>What do you want to create?</h3>
    //         <div className="input-group" >
    //           <div style={{position: "relative"}}>
    //           <input
    //             type="text"
    //             placeholder="Specify a writing task"
    //             value={task}
    //             onChange={handleInputChange}
    //           />
    //           </div>

    //           {filteredSuggestions.length > 0 && (
    //             <ul>
    //               {filteredSuggestions.map((suggestion, index) => (
    //                 <li key={index}>
    //                   <Link to={suggestion.link}>
    //                     {suggestion.text}
    //                   </Link>
    //                 </li>
    //               ))}
    //             </ul>
    //           )}
    //           <div style={{position: "relative"}}>
    //           <button className="start">
    //             Start <img src="/images/star.svg" alt="stars" />
    //           </button>
    //           </div>
    //         </div>
    //         <div className="cards">
    //           <Link to='/AmazonProduct' className="mini-card">
    //             <h4>Amazon Product Description</h4>
    //             <p>Create compelling product descriptions for Amazon listings.</p>
    //           </Link>
    //           <Link to='/BlogPost' className="mini-card">
    //             <h4>Blog Post Conclusion Paragraph</h4>
    //             <p>Wrap up your blog posts with an engaging conclusion paragraph.</p>
    //           </Link>
    //           <Link to='/Commands' className="mini-card">
    //             <h4>Commands</h4>
    //             <p>Tell charli exactly what to write with a command.</p>
    //           </Link>
    //           <Link to='/CreativeStory' className="mini-card">
    //             <h4>Creative Story</h4>
    //             <p>Write creative stories to engage readers.</p>
    //           </Link>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default CreateCard;
