import { Outlet } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import "./layout.scss";
import { Sidebars } from "../sidebar/Sidebars";
import { AuthContext } from "../../Context/AuthContext";

export const PanelLayout = () => {
  const { _auth } = useContext(AuthContext);
  const [openSidebar, setOpenSidebar] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{
      width: '100vw',
      height: '100vh',
      padding: '0px',
      margin: '0px',
      border: '0px',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      {openSidebar &&
        <div className="panel-sidebar">
          <Sidebars />
        </div>}
      <div style={{
        flex: 1,
        height: "100%",
      }}>
        {_auth && <Outlet />}
      </div>
    </div>
  );
};
