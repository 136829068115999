import React, { useEffect, useState, useContext } from "react";
import styles from "./Profile.module.css";
import { AuthContext } from "../../Context/AuthContext";

const Profile = () => {
  const [user, setUser] = useState(null);
  const [image, setImage] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    image: "",
  });
  const { userInfo, setUserInfo } = useContext(AuthContext);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    console.log(
      "FROM LOCAL STORAGE PROFILE PAGE",
      localStorage.getItem("email")
    );
    console.log("storedUser", storedUser);
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
      setFormData({
        firstname: parsedUser.firstname,
        lastname: parsedUser.lastname,
        image: parsedUser.image,
      });
    }
  }, []);

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImage(selectedImage);
  };

  const handleImageUpload = async () => {
    if (!image) {
      alert("Please select an image to upload.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("file", image);

      const token = localStorage.getItem("token");
      if (!token) {
        alert("No token found, please log in.");
        return;
      }

      const response = await fetch("http://127.0.0.1:5000/upload", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const data = await response.json();

      if (response.ok) {
        setUser({ ...user, image: data.image });
        localStorage.setItem(
          "user",
          JSON.stringify({ ...user, image: data.image })
        );
      } else {
        console.error("Image upload failed:", data.message);
        alert(data.message);
      }
    } catch (error) {
      console.error("Error during image upload:", error.message);
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleProfileUpdate = async () => {
    const token = localStorage.getItem("jwt");
    if (!token) {
      alert("No token found, please log in.");
      return;
    }
    console.log('FORMADDATAXXX', formData)
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user_crud/update_info`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        setUser({ ...user, ...formData });
        localStorage.setItem("user", JSON.stringify({ ...user, ...formData }));
        setUserInfo({
          'first_name': data.firstname,
          'last_name': data.lastname,
        })

        sessionStorage.setItem('first_name', data.firstname)
        sessionStorage.setItem('last_name', data.lastname)

        setEditMode(false);
      } else {
        console.error("Profile update failed:", data.message);
        alert(data.message);
      }
    } catch (error) {
      console.error("Error during profile update:", error.message);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        overflow: "hidden",
        background:
          "linear-gradient(to right top, rgb(134, 239, 172), rgb(59, 130, 246), rgb(147, 51, 234))",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className={styles.card}>
        <h1 className={styles.title}>Profile</h1>
        <div className={styles.userInfo}>
          {editMode ? (
            <>
              <label className={styles.label}>First Name:</label>
              <input
                type="text"
                name="firstname"
                value={formData.firstname}
                onChange={handleInputChange}
                className={styles.input}
              />
              <label className={styles.label}>Last Name:</label>
              <input
                type="text"
                name="lastname"
                value={formData.lastname}
                onChange={handleInputChange}
                className={styles.input}
              />
            </>
          ) : (
            <>
              <p>
                <span className={styles.label}>First Name:</span>{" "}
                {userInfo.first_name}
              </p>
              <p>
                <span className={styles.label}>Last Name:</span>{" "}
                {userInfo.last_name}
              </p>
            </>
          )}
          <p>{/* <span className={styles.label}>Email:</span> YO EEMAIL */}</p>
          {/* {user.image && (
            <img
              src={`http://localhost:5000/uploads/${user.image}`}
              alt="Profile"
              className={styles.profileImage}
            />
          )} */}
        </div>
        {editMode && (
          <>
            {/* <div>
              <label className={styles.label} htmlFor="image">
                Profile Image:
              </label>
              <input
                type="file"
                id="image"
                accept="image/*"
                onChange={handleImageChange}
                className={styles.input}
              />
            </div> */}
            {/* <div className="button-container">
              <button onClick={handleImageUpload} className={styles.uploadBtn}>
                Upload Image
              </button>
            </div> */}
          </>
        )}
        <div className="button-container">
          {editMode ? (
            <>
              <button
                onClick={handleProfileUpdate}
                className={styles.uploadBtn}
              >
                Save Changes
              </button>
              <button
                onClick={() => setEditMode(false)}
                className={styles.uploadBtn}
                style={{ padding: "10px 40px" }}
              >
                Cancel
              </button>
            </>
          ) : (
            <button
              onClick={() => setEditMode(true)}
              className={styles.editBtn}
              style={{ padding: "10px 23px" }}
            >
              Edit Profile
            </button>
          )}
        </div>
      </div>

     

    </div>
  );
};

export default Profile;
