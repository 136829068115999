import React, { useState, useEffect } from "react";
import { CardContent, Typography, Dialog, DialogContent, IconButton } from "@mui/material";
import axios from "axios";
import "./Bank.scss";
import ReactMarkdown from "react-markdown";
import FileCopyIcon from "@mui/icons-material/FileCopy";

const Bank = () => {
  const [open, setOpen] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [responses, setResponses] = useState([]);
  const [userEmail, setUserEmail] = useState(null);
  const [copiedIndex, setCopiedIndex] = useState(null); // Track which item is copied

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUserEmail(parsedUser.email);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/charli/responsegpt`,
          {
            params: {
              email: userEmail,
              card_types: [
                "Amazon Product Description",
                "Amazon Product feature",
                "Blog Post Conclusion",
                "Blog Post Intro",
                "Blog Post Outline",
                "Blog Post Topic Ideas",
                "Business/Product Details",
                "Commands",
                "Company Bio",
                "Content Improver",
                "Creative Story",
                "Email Subject Line",
                "Facebook Add",
                "Job Description",
                "LinkedIn Topic Ideas",
              ].join(","),
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setResponses(response.data);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    fetchData();

    return () => {
      setResponses([]);
    };
  }, [userEmail]);

  const handleClickOpen = (generated_response) => {
    setSelectedDescription(generated_response);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCopyToClipboard = (text, index) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedIndex(index); // Show "Copied!" for the clicked item
        setTimeout(() => setCopiedIndex(null), 1000); // Hide "Copied!" after 1 second
      })
      .catch((error) => {
        console.error("Error copying text to clipboard:", error);
      });
  };

  if (loading) return <div>Loading...</div>;
  if (error)
    return (
      <div className="no-content-div">
        <p className="no-content">No Content</p>{" "}
      </div>
    );

  return (
    <div className="bank-container">
      <p style={{fontSize:'25px',fontWeight:'600',marginTop:'28px',marginLeft:'20px'}}>Content Bank</p>
      <div className="bank-card-list">
        
        {responses.map((response, index) => (
          <div
            key={index}
            className="bank-card"
            onClick={() => handleClickOpen(response.generated_response)}
          >
            <CardContent className="bank-card-content">
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "8px",
                }}
              >
                <div
                  variant="h5"
                  component="div"
                  className="bank-title"
                >
                  {response.card_type}
                </div>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCopyToClipboard(response.generated_response, index);
                    }}
                  >
                    <FileCopyIcon />
                  </IconButton>
                  {copiedIndex === index && (
                    <span className="copy-text">Copied!</span>
                  )}
                </div>
              </div>
              <div variant="body2" className="bank-description">
                <ReactMarkdown>
                  {response.generated_response}
                </ReactMarkdown>
              </div>
            </CardContent>
          </div>
        ))}
        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            <Typography variant="body2">
              <ReactMarkdown>{selectedDescription}</ReactMarkdown>
            </Typography>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default Bank;
