// import React, { useState } from 'react';
import React, { useEffect, useState } from "react";

import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import useFetchData from "./useFetchData";

function AmazonProduct() {
  const [formData, setFormData] = useState({
    productName: '',
    keyFeatures: '',
    toneOfVoice: ''
  });
  const [apiResponse, setApiResponse] = useState(null);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); // State to track loading state
  const [saving, setSaving] = useState(false); // State to track saving state
  const { data, loadingHook, errorHook } = useFetchData(`${process.env.REACT_APP_API_URL}/user_crud/retrieve_voices`);



  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
  
    }
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setApiResponse(null); // Clear previous response
    setError(null); // Clear any previous error

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/charli/charli/Amazon_product`, {
        params: {
          productName: formData.productName,
          keyFeatures: formData.keyFeatures,
          toneOfVoice: formData.toneOfVoice
        },
        headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}` // Set the Authorization header with Bearer token
        }
      });
      setApiResponse(response.data);
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred while fetching data.');
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (!apiResponse) return;

    setSaving(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/charli/save`,
        {
          productName: formData.productName,
          keyFeatures: formData.keyFeatures,
          toneOfVoice: formData.toneOfVoice,
          generatedResponse: apiResponse.response,
          // email: user.email, // Make sure this retrieves the correct user email
          cardType: 'Amazon Product Description' // Replace with actual email or retrieve from user session
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`, // Set Bearer token in Authorization header
            "Content-Type": "application/json"
          },
        }
      );

      if (response.status === 200) {
        alert('Response saved successfully!');
      }
    } catch (error) {
      console.error('Save Error:', error);
      alert('An error occurred while saving the response.');
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="form-container">
      <div className="left-panel">
        <form style={{ maxWidth: '400px', margin: 'auto' }} onSubmit={handleSubmit}>
          <div style={{ marginBottom: '15px' }}>
            <span className="form-title">Amazon Product Description</span>
            <p>Create compelling product descriptions for Amazon listings.</p>
          </div>

          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="productName">Product Name:</label>
            <input
              id="productName"
              type="text"
              placeholder="Enter product name"
              style={{ width: '100%', padding: '9px' }}
              onChange={handleChange}
              value={formData.productName}
            />
          </div>
          
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="keyFeatures">Key Features/Benefits:</label>
            <textarea
              id="keyFeatures"
              placeholder="Enter key features or benefits"
              style={{ width: '100%', minHeight: '100px', padding: '5px' }}
              onChange={handleChange}
              value={formData.keyFeatures}
            ></textarea>
          </div>

          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="toneOfVoice" >Tone of Voice:</label>
            <select 
              id="toneOfVoice"
             className="dropdown-style"
              onChange={handleChange}
              value={formData.toneOfVoice}
            >
              <option value="" className="option-card">Select a tone...</option>
              {!loadingHook && data?.voices?.map((voice) => (
                <option key={voice.id} value={voice.name}>
                  {voice.name}
                </option>
              ))}
            </select>
            {loadingHook && <p>Loading voices...</p>}
            {errorHook && <p>Error loading voices: {errorHook.message}</p>}
          </div>
          
          <button type="submit" id='submit-btn' disabled={loading}>
            {loading ? 'Generating...' : 'Generate'}
          </button>
        </form>
      </div>

      <div className="right-panel">
        <div className="response-window" >
          {loading && <p>Loading...</p>}
          {error && <p>Error: {error}</p>}
          {apiResponse && (
            <div>
              <ComponentWithApiResponse data={apiResponse['response']} />
              <button onClick={handleSave} id='save-btn' disabled={saving}>
                {saving ? 'Saving...' : 'Save Response'}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function ComponentWithApiResponse({ data }) {
  return (
    <div>
      <ReactMarkdown>{data}</ReactMarkdown>
    </div>
  );
}

export default AmazonProduct;
