import axios from "axios";
import { MdEmail } from "react-icons/md";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AiFillCloseCircle } from "react-icons/ai";
import { RiLockPasswordFill } from "react-icons/ri";

import "./css/SigninStyle.css";
import {
  signInWithEmail,
  handleSignInError,
} from "../UserAuthentication/Config";
import { AuthContext } from "../../Context/AuthContext";

const Signin = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [serverError, setServerError] = useState("");

  const { _setAuth, setUserInfo } = useContext(AuthContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const validateForm = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!user.email) {
      errors.email = "Email is required";
    } else if (!emailRegex.test(user.email)) {
      errors.email = "Please enter a valid email address";
    }
    if (!user.password) {
      errors.password = "Password is required";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    setServerError("");

    try {
      const signInStatus = await signInWithEmail(user.email, user.password);
      const idToken = await signInStatus.user.getIdToken();

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/user_login`,
        { idToken: idToken, email: user.email },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        localStorage.setItem("jwt", response.data.token);
        sessionStorage.setItem("first_name", response.data.first_name);
        sessionStorage.setItem("last_name", response.data.last_name);
        setUserInfo({
          first_name: response.data.first_name,
          last_name: response.data.last_name,
        });
        _setAuth(true);
        navigate("/dashboard");
      } else if (response.status === 403) {
        console.log("THE 403 ERROR")
      } else {
        navigate("/");
      }
    } catch (error) {
      console.error("Error logging in:", error);
      const errorMessage = handleSignInError(error.message);
      setServerError(errorMessage);
    }

    setLoading(false);
  };

  return (
    <section className="sign-in">
      <div className="container-signIn">
        <div className="signin-content">
          <div className="signin-form">
            <h2 className="form-title">Login to your Account</h2>
            <p style={{ fontSize: '10px', color: 'gray' }}>Please enter your information to access your account</p>
            <form
              onSubmit={handleSubmit}
              className="register-form"
              id="login-form"
              style={{
                margin: 0,
              }}
            >
              <div style={{
                width: "100%",
                display: "flex",
                marginBottom: "10px",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
              }}>
                <span style={{
                  color: "gray",
                  fontSize: "12px",
                }}>Email</span>
                <div style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#E5E4E2",
                  padding: "10px 15px",
                  borderRadius: "5px",
                }}>
                  <MdEmail color="gray" />
                  <input
                    style={{
                      width: "100% !important",
                      backgroundColor: "transparent"
                    }}
                    type="email"
                    name="email"
                    id="your_email"
                    placeholder="Enter Your Email"
                    onChange={handleChange}
                    value={user.email}
                  />
                </div>
              </div>
              <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
              }}>
                <span style={{
                  color: "gray",
                  fontSize: "12px",
                }}>Password</span>
                <div style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#E5E4E2",
                  padding: "10px 15px",
                  borderRadius: "5px",
                }}>
                  <RiLockPasswordFill color="gray" />
                  <input
                    style={{
                      width: "100% !important",
                      backgroundColor: "transparent"
                    }}
                    type="password"
                    name="password"
                    id="your_pass"
                    placeholder="Enter Your Password"
                    onChange={handleChange}
                    value={user.password}
                  />
                </div>
                {formErrors.password && (
                  <span className="error" style={{ color: "red" }}>
                    {formErrors.password}
                  </span>
                )}
              </div>
              {serverError && (
                <div
                  className="error-container"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "1rem",
                    marginBottom: "0",
                    padding:
                      "0.75rem 1rem",
                    backgroundColor:
                      "#fbb6ce",
                    borderRadius:
                      "0.5rem",
                    border:
                      "1px solid #f56565",
                    gap: "0.5rem",
                  }}
                >
                  <AiFillCloseCircle
                    className="icon"
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                    }}
                  />
                  <p
                    className="error-message"
                    style={{
                      fontWeight: "bold",
                      color:
                        "#1a202c",
                    }}
                  >
                    {serverError}
                  </p>
                </div>
              )}

              <button type="submit" disabled={loading} style={{
                width: "100%",
                border: "none",
                color: "white",
                padding: "7.5px",
                marginTop: "20px",
                borderRadius: "5px",
                backgroundColor: "#2D6CDF",
              }}>
                {loading ? "Logging In..." : "Log in"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Signin;
