import React, { useEffect, useState } from "react";
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import useFetchData from "./useFetchData";

function BlogPost() {
  const [formData, setFormData] = useState({
    mainPoints: '',
    callToAction: '',
    toneOfVoice: ''
  });
  const [apiResponse, setApiResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [user, setUser] = useState(null);
  const { data, loadingHook, errorHook } = useFetchData(`${process.env.REACT_APP_API_URL}/user_crud/retrieve_voices`);


  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
    }
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setApiResponse(null);
    setError(null);

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/charli/charli/Blog_post`, {
        params: {
          mainpoint: formData.mainPoints,
          action: formData.callToAction,
          tone: formData.toneOfVoice,
          // email: user.email,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`, // Set Bearer token in Authorization header
        },
      });
      setApiResponse(response.data);
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred while fetching data.');
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (!apiResponse) return;
    setSaving(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/charli/save`, 
        {
          mainpoint: formData.mainPoints,
          action: formData.callToAction,
          tone: formData.toneOfVoice,
          generatedResponse: apiResponse.response,
          cardType: 'Blog Post Conclusion'
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`, // Set Bearer token in Authorization header
            "Content-Type": "application/json"
          }
        }
      );
    
      if (response.status === 200) {
        alert('Response saved successfully!');
      } else {
        console.error('Save Response:', response);
        alert('Failed to save the response.');
      }
    } catch (error) {
      // Check if there is a response from the server
      if (error.response) {
        // You can print custom error messages based on the status code
        if (error.response.status === 429) {
          alert('You have exceeded your rate limit. Please try again later.');
        } else if (error.response.status === 500) {
          alert('Server error. Please try again later.');
        } else if (error.response.status === 401) {
          alert('Unauthorized. Please log in again.');
        } else {
          alert(`An error occurred: ${error.response.data.message || error.response.data}`);
        }
        console.error('Error Response:', error.response);
      } else {
        // If there is no response (e.g. network error)
        alert(`An error occurred: ${error.message}`);
        console.error('Error Message:', error.message);
      }
    } finally {
      setSaving(false);
    }
    
  };

  return (
    <div className="form-container">
      <div className="left-panel">
        <form style={{ maxWidth: '400px', margin: 'auto' }} onSubmit={handleSubmit}>
          <div style={{ marginBottom: '15px' }}>
            <span className="form-title">Blog Post Conclusion</span>
            <p>Provide the main points and tone for your blog post.</p>
          </div>
          <div style={{ marginBottom: '15px' }}>
            <textarea
              id="mainPoints"
              placeholder="The importance of time management. Tips for better time management. Benefits of effective time management."
              style={{ width: '100%', minHeight: '100px', padding: '5px' }}
              onChange={handleChange}
              value={formData.mainPoints}
            ></textarea>
          </div>
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="call-to-action">Call to Action:</label>
            <textarea
              id="callToAction"
              placeholder="Share your time management tips with us in the comments below!"
              style={{ width: '100%', minHeight: '100px', padding: '5px' }}
              onChange={handleChange}
              value={formData.callToAction}
            ></textarea>
          </div>
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="toneOfVoice" >Tone of Voice:</label>
            <select 
              id="toneOfVoice"
             className="dropdown-style"
              onChange={handleChange}
              value={formData.toneOfVoice}
            >
              <option value="">Select a tone...</option>
              {!loadingHook && data?.voices?.map((voice) => (
                <option key={voice.id} value={voice.name}>
                  {voice.name}
                </option>
              ))}
            </select>
            {loadingHook && <p>Loading voices...</p>}
            {errorHook && <p>Error loading voices: {errorHook.message}</p>}
          </div>
          <button type="submit" id='submit-btn' disabled={loading}>
            {loading ? 'Generating...' : 'Generate'}
          </button>
        </form>
      </div>
      <div className="right-panel">
        <div className="response-window">
          {loading && <p>Loading...</p>}
          {error && <p>Error: {error}</p>}
          {apiResponse && (
            <div>
              <ComponentWithApiResponse data={apiResponse['response']} />
              <button onClick={handleSave} id='submit-btn' disabled={saving}>
                {saving ? 'Saving...' : 'Save Response'}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function ComponentWithApiResponse({ data }) {
  return (
    <div>
      <ReactMarkdown>{data}</ReactMarkdown>
    </div>
  );
}

export default BlogPost;
