

import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Create a context
export const AuthContext = createContext();

// Create a provider component
export const AuthProvider = ({ children }) => {
  const [_auth, _setAuth] = useState(false);
  const [userInfo, setUserInfo] = useState({
    first_name: null,
    last_name: null,
  });

  const navigate = useNavigate();

  const isTokenExpired = (token) => {
    try {
      console.log("from context",token)
      const payload = JSON.parse(atob(token.split('.')[1]));
      return payload.exp * 1000 < Date.now();
    } catch (error) {
      console.error('Invalid token:', error);
      return true;
    }
  };

  useEffect(() => {
    const jwt = localStorage.getItem('jwt');
    console.log('NAME YO FOR AUTH CONTEXT', sessionStorage.getItem('name'));
    
    if (jwt) {
      if (isTokenExpired(jwt)) {
        console.log('JWT is expired');
        // alert("your session has expired! Login again")
        localStorage.removeItem('jwt'); // Remove invalid JWT
        sessionStorage.clear(); // Clear session-related info
        _setAuth(false);
        navigate('/'); // Redirect to home page
      } else {
        _setAuth(true);
        setUserInfo({
          first_name: sessionStorage.getItem('first_name'),
          last_name: sessionStorage.getItem('last_name'),
        });
      }
    } else {
      _setAuth(false);
    }
    console.log('JWT FROM YO CONTEXT', jwt);
  }, [_auth, navigate]);

  return (
    <AuthContext.Provider value={{ _auth, _setAuth, userInfo, setUserInfo }}>
      {children}
    </AuthContext.Provider>
  );
};
