import Bizz from "./pages/Bizz/Bizz";
import Bank from "./pages/Bank/Bank";
import Chat from "./pages/Chat/Chat";
import Voice from "./pages/Voice/Voice";
import Cards from "./components/Cards/Cards";
import BlogPost from "./components/forms/BlogPost";
import Profile from "./components/Profile/Profile";
import Commands from "./components/forms/Commands";
import Dashboard from "./pages/Dashboard/Dashboard";
import CompanyBio from "./components/forms/CompanyBio";
import FacebookAds from "./components/forms/FacebookAds";
import Signin from "./components/newRegistration/Signin";
import Chatwithcharli from "./pages/Charli/Chatwithcharli";
import AmazonProduct from "./components/forms/AmazonProduct";
import BlogPostIntro from "./components/forms/BlogPostIntro";
import CreativeStory from "./components/forms/CreativeStory";
import { PanelLayout } from "./components/layout/PanelLayout";
import JobDescription from "./components/forms/JobDescription";
import AmazonPfeature from "./components/forms/AmazonPfeature";
import ContentImprover from "./components/forms/ContentImprover";
import BlogPostOutline from "./components/forms/BlogPostOutline";
import EmailSubjectLines from "./components/forms/EmailSubjectLines";
import BlogPostTopicIdeas from "./components/forms/BlogPostTopicIdeas";
import LinkedInTopicIdeas from "./components/forms/LinkedInTopicIdeas";
import BusinessOrProductName from "./components/forms/BusinessOrProductName";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./Context/AuthContext";

const App = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Signin />} />
        <Route element={<PanelLayout />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/voice" element={<Voice />} />
          <Route path="/bizz" element={<Bizz />} />
          <Route path="/bank" element={<Bank />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/cards" element={<Cards />} />
          <Route path="/cards/amazon-p-feature" element={<AmazonPfeature />} />
          <Route path="/cards/amazon-product" element={<AmazonProduct />} />
          <Route path="/cards/blog-post" element={<BlogPost />} />
          <Route path="/cards/blog-post-intro" element={<BlogPostIntro />} />
          <Route path="/cards/blog-post-outline" element={<BlogPostOutline />} />
          <Route path="/cards/blog-post-topic-ideas" element={<BlogPostTopicIdeas />} />
          <Route
            path="/cards/business-product-name"
            element={<BusinessOrProductName />}
          />
          <Route path="/cards/commands" element={<Commands />} />
          <Route path="/cards/company-bio" element={<CompanyBio />} />
          <Route path="/cards/content-improver" element={<ContentImprover />} />
          <Route path="/cards/creative-story" element={<CreativeStory />} />
          <Route path="/cards/email-subject-lines" element={<EmailSubjectLines />} />
          <Route path="/cards/facebook-ads" element={<FacebookAds />} />
          <Route path="/cards/job-description" element={<JobDescription />} />
          <Route path="/cards/linkedin-topic-ideas" element={<LinkedInTopicIdeas />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/chat-with-charli" element={<Chatwithcharli />} /></Route>
      </Routes>
    </AuthProvider>
  )
}

export default App