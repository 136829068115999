import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchData = (url) => {
  const [data, setData] = useState(null);
  const [loadingHook, setLoadingHook] = useState(true);
  const [errorHook, setErrorHook] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Retrieve JWT token from local storage
        const token = localStorage.getItem('jwt');

        // Make the API request with the Bearer token
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`, // Include Bearer token
          },
        });

        console.log('THE FETCHE DATA RESPONSE',response)
        setData(response.data);
      } catch (err) {
        setErrorHook(err);
      } finally {
        setLoadingHook(false);
      }
    };

    fetchData();
  }, [url]);

  return { data, loadingHook, errorHook };
};

export default useFetchData;
