import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { FaBars } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { CiLogout } from "react-icons/ci";
import "./sidebars.scss";
import dashboard from "../../assets/images/dashboard.svg";
import voice from "../../assets/images/voice.svg";
import bizz from "../../assets/images/bizz.svg";
import bank from "../../assets/images/bank.svg";
import CardsImage from "../../assets/images/edit.svg";
import TrainChatbotModal from "../Modal/TrainChatbotModal";
import { AuthContext } from "../../Context/AuthContext";
import { MdManageHistory } from "react-icons/md";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { AiOutlineHome } from "react-icons/ai";
import { FaRegPenToSquare } from "react-icons/fa6";
import { MdOutlineRecordVoiceOver } from "react-icons/md";
import { BsInfoCircleFill } from "react-icons/bs";
import { MdOutlineContentPasteSearch } from "react-icons/md";




export const Sidebars = () => {
  const location = useLocation();
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [activeMenu, setActiveMenu] = useState(() => location.pathname.toLowerCase());

  const { userInfo } = useContext(AuthContext);
  const [user, setUser] = useState({ firstname: "", lastname: "", image_data: "", image: " " });

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData) {
      setUser(userData);
    }
  }, []);

  useEffect(() => {
    setActiveMenu(location.pathname.toLowerCase());
  }, [location.pathname]);

  const handleMenuClick = (path) => {
    setActiveMenu(path.toLowerCase());
    if (window.innerWidth < 768) setSidebarVisible(false);
  };

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      {/* Hamburger Menu */}
      <div className="hamburger-menu" onClick={toggleSidebar}>
        <FaBars />
      </div>

      {/* Sidebar */}
      <Sidebar className={`sidebar ${isSidebarVisible ? "visible" : "hidden"}`}>
        <Menu style={{ width: "100%", height: "calc(100vh - 66px)" }}>
          <p className="logo">ChatBot</p>

          <MenuItem
            onClick={() => handleMenuClick("/dashboard")}
            component={<Link to="/dashboard" />}
            className={`menu-item ${activeMenu === "/dashboard" ? "active-link" : ""}`}
          >
            {/* <img src={dashboard} alt="Dashboard" />
             */}
             <div style={{display:'flex',alignItems:'center',gap:'10px'}}>
             <AiOutlineHome className="nav-icon" />
             <p className="nav-items">Dashboard</p>
             </div>
          </MenuItem>

          <SubMenu style={{marginBottom:'-10px'}}
  label={<span className="nav-items" style={{display:'flex',alignItems:'center',gap:'10px'}} ><MdManageHistory style={{fontSize:'15px',color:'gray'}} /><span>Content Management</span></span>} // Apply 'nav-items' to the label
  className={`menu-item ${activeMenu.includes("/content-management") ? "active-link" : ""}`}
>
            <MenuItem style={{marginTop:'-15px'}}
              onClick={() => handleMenuClick("/cards")}
              component={<Link to="/cards" />}
              className={`submenu-item ${activeMenu === "/cards" ? "active-link" : ""}`}
            >
              {/* <img src={CardsImage} alt="Cards" /> */}
              <div style={{display:'flex',alignItems:'center',gap:'10px'}}>
              <FaRegPenToSquare className="nav-icon"/>
              <p className="nav-items">Content Creation</p>
              </div>
            </MenuItem>
            <MenuItem 
              onClick={() => handleMenuClick("/voice")}
              component={<Link to="/voice" />}
              className={`submenu-item ${activeMenu === "/voice" ? "active-link" : ""}`}
            >
              {/* <img src={voice} alt="Voice" /> */}
              <div style={{display:'flex',alignItems:'center',gap:'10px'}}>
              <MdOutlineRecordVoiceOver className="nav-icon" />
              <p className="nav-items">Brand Voice</p>
              </div>
            </MenuItem>

            

            

            <MenuItem 
              onClick={() => handleMenuClick("/bizz")}
              component={<Link to="/bizz" />}
              className={`submenu-item ${activeMenu === "/bizz" ? "active-link" : ""}`}
            >
              {/* <img src={bizz} alt="Business Info" /> */}
              <div style={{display:'flex',alignItems:'center',gap:'10px'}}>
              <BsInfoCircleFill className="nav-icon"/>
              <p className="nav-items">Business Info</p>
              </div>
            </MenuItem>
            <MenuItem style={{marginBottom:'-20px'}}
              onClick={() => handleMenuClick("/bank")}
              component={<Link to="/bank" />}
              className={`submenu-item ${activeMenu === "/bank" ? "active-link" : ""}`}
            >
              {/* <img src={bank} alt="Content Bank" /> */}
              <div style={{display:'flex',alignItems:'center',gap:'10px'}}>
              <MdOutlineContentPasteSearch className="nav-icon"/>

              <p className="nav-items">Content Bank</p>
              </div>
            </MenuItem>
          </SubMenu>

          <MenuItem
            onClick={() => {
              handleMenuClick("/chat-with-charli");
              setModalShow(true);
            }}
            component={<Link to="/chat-with-charli" />}
            className={`menu-item ${activeMenu === "/chat-with-charli" ? "active-link" : ""}`}
          >
            {/* <img src={bank} alt="Chat With Charli" className="nav-items" /> */}
           <div style={{display:'flex',alignItems:'center',gap:'10px'}}>
           <IoChatbubbleEllipsesOutline className="nav-icon" />
           <p className="nav-items">Chat With Charli</p>
           </div>
          </MenuItem>
        </Menu>

        <Link to="/" className="logo-link">
          <div className="logo-container" style={{ padding: "4px 20px", borderRadius: "8px", backgroundColor: "red" }}>
            <div className="logo-image-container">
              <CiLogout
                className="user-icon"
                style={{ color: "white", backgroundColor: "red", fontWeight: "600", fontSize: "20px" }}
              />
            </div>
            <div className="user-name">
              <p style={{ color: "white", backgroundColor: "red", marginTop: "4px", fontSize: "14px",fontWeight:'600' }}>Logout</p>
            </div>
          </div>
        </Link>
      </Sidebar>

      <TrainChatbotModal
        show={modalShow}
        handleClose={() => setModalShow(false)}
        setModalShow={setModalShow}
      />
    </>
  );
};
