import React, { useState } from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

import {
  FaClipboardList,
  FaTasks,
  FaPen,
  FaRegEdit,
  FaLightbulb,
  FaBriefcase,
  FaHistory,
  FaEnvelope,
  FaFacebook,
  FaLinkedin,
  FaArrowRight,
} from "react-icons/fa";
import { MdContentPasteSearch, MdOutlineDescription } from "react-icons/md";
import { RiSlashCommands2 } from "react-icons/ri";
import { LiaBiohazardSolid } from "react-icons/lia";
import "./Cards.css"; // Import the CSS file

const cards = [
  {
    id: "amazon-product",
    title: "Amazon Product Description",
    description:
      "Create compelling product descriptions for Amazon listings.",
    icon: FaClipboardList, // Represents lists or organized content
  },
  {
    id: "amazon-p-feature",
    title: "Amazon Product Features ",
    description:
      'Create key feature and benefit bullet points for Amazon listings.',
    icon: FaTasks, // Represents tasks or feature lists
  },
  {
    id: "blog-post",
    title: "Blog Post Conclusion",
    description:
      "Wrap up your blog posts with an engaging conclusion paragraph.",
    icon: FaPen, // Represents writing or editing
  },
  {
    id: "blog-post-intro",
    title: "Blog Post Intro Paragraph",
    description: "Write an engaging opening paragraph for your blog post.",
    icon: FaRegEdit, // Represents editing or writing
  },
  {
    id: "blog-post-outline",
    title: "Blog Post Outline",
    description:
      'Create lists and outlines for articles, for example for "How to" style blog posts and articles.',
    icon: FaClipboardList, // Represents lists or outlines
  },
  {
    id: "blog-post-topic-ideas",
    title: "Blog Post Topic Ideas",
    description:
      "Generate new blog post topics that will engage readers and rank well on Google.",
    icon: FaLightbulb, // Represents ideas or inspiration
  },
  {
    id: "business-or-product-name",
    title: "Business or Product Name",
    description: "Generate a winning name for your business or product.",
    icon: FaBriefcase, // Represents business or professional tasks
  },
  {
    id: "commands",
    title: "Commands",
    description: "Tell charli exactly what to write with a command.",
    icon: RiSlashCommands2, // Represents commands or instructions
  },
  {
    id: "creative-story",
    title: "Creative Story",
    description: "Write creative stories to engage readers.",
    icon: FaHistory, // Represents storytelling or history
  },
  {
    id: "email-subject-lines",
    title: "Email Subject Lines",
    description: "Get your emails opened with irresistible subject lines.",
    icon: FaEnvelope, // Represents email or communication
  },
  {
    id: "company-bio",
    title: "Company Bio",
    description: "Share your company's story with a compelling bio.",
    icon: LiaBiohazardSolid, // Represents unique or attention-grabbing content
  },
  {
    id: "content-improver",
    title: "Content Improver",
    description:
      "Enhance a piece of content by rewriting it to be more engaging, creative, and captivating.",
    icon: MdContentPasteSearch, // Represents content improvement or editing
  },
  {
    id: "facebook-ads",
    title: "Facebook Ad Primary Text",
    description:
      "Craft compelling primary text for Facebook ads that attract users.",
    icon: FaFacebook, // Represents Facebook or social media
  },
  {
    id: "job-description",
    title: "Job Description",
    description:
      "Create a clear and concise job description to attract suitable candidates.",
    icon: MdOutlineDescription, // Represents descriptions or job details
  },
  {
    id: "linkedin-topic-ideas",
    title: "LinkedIn Topic Ideas",
    description:
      "Get inspired with LinkedIn topic ideas to share with your network.",
    icon: FaLinkedin, // Represents LinkedIn or professional networking
  },
];

const Cards = () => {
  const navigate = useNavigate();
  const [hoveredCard, setHoveredCard] = useState(null);

  const handleCardClick = (route) => {
    navigate(route);
    window.scrollTo({ top: 0, behavior: "auto" });
  };

  const handleMouseEnter = (cardId) => {
    setHoveredCard(cardId);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  const isHovered = (cardId) => cardId === hoveredCard;

  return (
   

     <div className="container-for-cards">
     <h1 className="top-title">Cards</h1>

      {cards.map((card) => {
        const IconComponent = card.icon; // Get the icon component from the card
        return (
          <div
            key={card.id}
            className="all-card"
            onClick={() => handleCardClick(`/cards/${card.id}`)}
            onMouseEnter={() => handleMouseEnter(card.id)}
            onMouseLeave={handleMouseLeave}
          >
            <div></div>
            {/* Icon at the top left corner */}

            <CardContent className="card-content">
             <div style={{display:'flex', justifyContent:'start'}}>
             <IconComponent
                className="icon "
                style={{ margin: "6px" }}
              />
             </div>
              {/* Card title with arrow icon */}
              <div
                style={{ marginTop: "10px" }}
                variant="h1"
                component="div"
                className={`title ${isHovered(card.id) ? "hovered" : ""}`}
              >
               <div className="tittle" > <p  style={{ fontSize: "14px", color: "black", fontWeight: "600",textAlign:'left', lineHeight: '16px',height:'25px' }}>{card.title}</p></div>
                {/* Arrow Icon */}
                <div><FaArrowRight
                  className={`arrow-icon ${isHovered(card.id) ? "rotate" : ""}`}
                /></div>
              </div>

              {/* Divider */}
              {/* <div
                className={`title ${
                  isHovered(card.id) ? "dividerhover" : "divider"
                }`}
              ></div> */}

              {/* Card description */}
              <div
                variant="body2"
                className={`description ${
                  isHovered(card.id) ? "descriptionhover" : ""
                }`}
              >
                {card.description}
              </div>
            </CardContent>
          </div>
        );
      })}
    </div>
   
  );
};

export default Cards;
