import React, { useState, useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";
import "./Chatwithcharli.scss";
import axios from "axios";
import AI from "../../assets/images/ai.svg";
// import User1 from "../../assets/images/user1.svg";
import { FaUserCircle } from "react-icons/fa";

function Chatwithcharli() {
  const [responses, setResponses] = useState([]);
  const [question, setQuestion] = useState("");
  const [userEmail, setUserEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [typingResponse, setTypingResponse] = useState(""); // Track typing animation
  const responsesEndRef = useRef(null);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUserEmail(parsedUser.email);
    }
  }, []);

  useEffect(() => {
    if (responsesEndRef.current) {
      responsesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [responses, typingResponse]);

  const handleStart = async () => {
    if (!question.trim()) return;

    setResponses((prevResponses) => [
      ...prevResponses,
      { question, response: "" },
    ]);
    setLoading(true);
    setQuestion("");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/charli/chat`,
        {
          email: userEmail,
          questions: [question],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      const data = response.data;

      // Start typing effect
      simulateTyping(data.responses[0], question);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const simulateTyping = (fullResponse, question) => {
    let currentIndex = 0;
    setTypingResponse("");

    const typingInterval = setInterval(() => {
      setTypingResponse((prev) => prev + fullResponse[currentIndex]);
      currentIndex++;
      if (currentIndex === fullResponse.length) {
        clearInterval(typingInterval);
        setResponses((prevResponses) => [
          ...prevResponses.slice(0, -1),
          { question, response: fullResponse },
        ]);
        setTypingResponse("");
      }
    }, 4); // Adjust speed by changing the interval time
  };

  const handleChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleClearChat = () => {
    setResponses([]);
  };

  return (
    <div className="messageBox">
      <p style={{ fontSize: '25px', fontWeight: 'bold', marginTop: '8px' }}>Chat with Charli</p>
      {/* <span className="logo">AI</span> */}
      <h4>How can I help you today?</h4>
      <div className="responses-container">
        {responses.map((res, index) => (
          <div key={index}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>

              <div className="response user">
                <div className="chat-message">
                  <div className="chat-message-inner">
                    <p>{res.question}</p>
                  </div>
                </div>
              </div>
              <FaUserCircle  style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "50%",
                  marginLeft: "10px",
                  marginTop: "20px",
                }} />
              {/* <img
                src={User1}
                alt="AI Avatar"
                className="avatar"
                style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "50%",
                  marginLeft: "10px",
                  marginTop: "20px",
                }}
              /> */}
            </div>

            {res.response && (
              <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start" }}>
                {/* AI Avatar */}
                <img
           src={AI}
           alt="AI Avatar"
           className="avatar"
           style={{
             width: "25px",
             height: "25px",
             borderRadius: "50%",
             marginRight: "10px",
             marginTop: "20px",
           }}
         />
                <div className="response ai">
                  <div className="chat-message">
                    <div className="chat-message-inner">
                      <ReactMarkdown>{res.response}</ReactMarkdown>
                    </div>
                  </div>
                </div>
              </div>
            )}

          </div>
        ))}
        {typingResponse && (
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
             <img
           src={AI}
           alt="AI Avatar"
           className="avatar"
           style={{
             width: "25px",
             height: "25px",
             borderRadius: "50%",
             marginRight: "10px",
             marginTop: "20px",
           }}
         />
            <div className="response ai">
              <div className="chat-message">
                <div className="chat-message-inner">
                  <ReactMarkdown>{typingResponse}</ReactMarkdown>
                </div>
              </div>
            </div>
          </div>
        )}
        {loading && (
       <div>
            <img
           src={AI}
           alt="AI Avatar"
           className="avatar"
           style={{
             width: "25px",
             height: "25px",
             borderRadius: "50%",
             marginRight: "10px",
             marginTop: "7px",
           }}
         />
          <div className="response ai">
            <div className="chat-message">
              <div className="chat-message-inner">
                <p>Loading...</p>
              </div>
            </div>
          </div>
       </div>
        )}
        <div ref={responsesEndRef} />
      </div>
      <div className="input-group">
        <input
          type="text"
          placeholder="Chat With Document..."
          value={question}
          onChange={handleChange}
          onKeyDown={(event) => {
            if (event.key === "Enter" && !loading) {
              handleStart();
            }
          }}
          disabled={loading}
        />
        <div className="button-group">
          <button className="start" onClick={handleStart} disabled={loading}>
            <img src="/images/arrow-up.svg" alt="Submit" />
          </button>
          <button className="clear-chat" onClick={handleClearChat}>
            Clear Chat
          </button>
        </div>
      </div>
      <p className="info">
        AI can make mistakes. Consider checking important information
      </p>
    </div>
  );
}

export default Chatwithcharli;
