import "./Score/Dashboard.scss";
import CreateCard from "./CreateCard/CreateCard";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import axios from "axios";
import Model from "./ModelProperty/Model";

export default function Dashboard() {
  const [chartData, setChartData] = useState([]);
  const [activeUsers, setActiveUsers] = useState(0);
  const [eventCount, setEventCount] = useState(0);
  const [days, setDays] = useState(7); // State to store selected number of days
  const [showModal, setShowModal] = useState(false);

  // Helper functions
  function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  // Keep original date format (YYYYMMDD) for internal processing
  function formatForRendering(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}${month}${day}`; // Internal format (YYYYMMDD)
  }

  function formatForDisplay(dateString) {
    if (!dateString) return "";
    const day = dateString.slice(6, 8);
    const monthIndex = parseInt(dateString.slice(4, 6), 10) - 1; // Month index (0-based)
    const year = dateString.slice(0, 4);

    // List of month names
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return `${day} ${months[monthIndex]} ${year}`;
  }

  // Format the date for X-axis and Tooltip display (e.g., "23 Nov 2023")
  // Fetch data on component mount and when `days` changes
  useEffect(() => {
    async function fetchAnalyticsData() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/google_analytics/get_own_analytics`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,

            },
          }
        );
        const apiData = response.data;
        console.log("Response from GA:", apiData);

        const dataByDate = {};
        apiData.forEach((item) => {
          const dateKey = item.date;
          dataByDate[dateKey] = {
            eventCount: Number(item.eventCount),
            activeUsers: Number(item.activeUsers),
          };
        });

        const endDate = new Date();
        endDate.setDate(endDate.getDate());
        const startDate = addDays(endDate, -days);

        const formattedData = [];
        let totalActiveUsers = 0;
        let totalEventCount = 0;

        for (let d = startDate; d <= endDate; d = addDays(d, 1)) {
          const dateString = formatForRendering(d); // Use the original format for processing
          const dataForDate = dataByDate[dateString] || {
            eventCount: 0,
            activeUsers: 0,
          };

          formattedData.push({
            name: dateString, // X-axis label (in YYYYMMDD format)
            pv: dataForDate.eventCount, // Event count (mapped to `pv`)
            uv: dataForDate.activeUsers, // Active users (mapped to `uv`)
          });

          totalActiveUsers += dataForDate.activeUsers;
          totalEventCount += dataForDate.eventCount;
        }

        setChartData(formattedData);
        setActiveUsers(totalActiveUsers);
        setEventCount(totalEventCount);

        console.log("Formatted Data for Recharts:", formattedData);
      } catch (error) {
        console.error("Error fetching analytics data:", error);
      }
    }

    fetchAnalyticsData();
  }, [days]);

  return (
    <div className="dashboard-main" style={{
      width: "100%",
      height: "100%",
      maxHeight: "100vh",
     overflowY: "auto",
      padding: "20px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
      alignItems: "start",
      justifyContent: "start",
    }}>
      {showModal && <Model closeModal={() => setShowModal(false)} />}

      {/* Title */}
      <div style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap:'10px'
      }}>
        <span style={{
          fontSize: "24px",
          fontWeight: "bold",
          flex: 1,
          textAlign: "left",
        }}>Dashboard</span>
        <button
          className="open"
          style={{
            fontSize:'14px',
            fontWeight:'600',
            borderRadius: "8px",
            backgroundColor: "#4169E1",
            padding: "10px 15px",
            border: "none",
            cursor: "pointer",
            marginLeft: '0px',
            color: 'white'
          }}
          onClick={() => setShowModal(true)}
        >
          Link Analytics
        </button>
      </div>

      {/* Content */}
      <div className="dashboard-content">
        <div style={{
          gridColumn: "span 1",
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          alignItems: "start",
          justifyContent: "start",
        }}>
          <div style={{
            width: "100%",
            display: "flex",
            alignItems: "end",
            justifyContent: "end"
          }}><Dropdown style={{ width: "auto" ,border:'1px solid black',borderRadius:'8px'  }}>
              <Dropdown.Toggle
                id="days-dropdown-toggle"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "8px 15px",
                  borderRadius: "8px",
                  color:'black',
                  border:'2px solid black',
                  backgroundColor: "white",
                  border: "none",
                }}
              >
                <p style={{ margin: 0 }}>{days} Days</p>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setDays(7)}>7 Days</Dropdown.Item>
                <Dropdown.Item onClick={() => setDays(10)}>10 Days</Dropdown.Item>
                <Dropdown.Item onClick={() => setDays(30)}>30 Days</Dropdown.Item>
                <Dropdown.Item onClick={() => setDays(60)}>60 Days</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown></div>
          {/* Chart */}
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                tickFormatter={(value) => formatForDisplay(value)}
                tick={{ fontSize: 8 }}
              />
              <YAxis />
              <Tooltip
                content={({ payload, label }) => {
                  if (!payload || !payload.length) return null;

                  const formattedDate = formatForDisplay(label);

                  const eventCount = payload[0]?.value;
                  const activeUsers = payload[1]?.value;

                  return (
                    <div
                      style={{
                        backgroundColor: "white",
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      <p>
                        <strong>{formattedDate}</strong>
                      </p>
                      <p>Event Count: {eventCount}</p>
                      <p>Active Users: {activeUsers}</p>
                    </div>
                  );
                }}
              />
              <Line
                type="monotone"
                dataKey="pv"
                stroke="#FF5733"
                name="Event Count"
              />
              <Line
                type="monotone"
                dataKey="uv"
                stroke="#1D57EE"
                name="Active Users"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div style={{
          gridColumn: "span 1",
          width: "100%",
          height: "100%",
        }}>
          <CreateCard />
        </div>
      </div>
    </div>
  );
}
