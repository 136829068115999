import React, { useState, useEffect } from "react";
import './Voice.scss';
import VoiceModal from '../../components/Modal/VoiceModal';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export default function Voice() {
  const [modalShow, setModalShow] = useState(false);
  const [tabs, setTabs] = useState(0);
  const [voices, setVoices] = useState([]);
  const [selectedVoices, setSelectedVoices] = useState(new Set());
  const [email, setEmail] = useState('YOUR_EMAIL_HERE'); // Update with actual email

  useEffect(() => {
    const fetchVoices = async () => {
      try {
        const token = localStorage.getItem('jwt'); // Retrieve the JWT from localStorage

        const response = await axios.get(`${apiUrl}/user_crud/retrieve_voices`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setVoices(response.data.voices || []);
      } catch (error) {
        console.error("Error fetching voices:", error.response?.data || error.message);
      }
    };

    fetchVoices();
  }, [voices]);

  const handleAddVoice = (newVoice) => {
    setVoices([...voices, newVoice]);
  };

  const handleCheckboxChange = (voiceId) => {
    setSelectedVoices((prev) => {
      const newSelection = new Set(prev);
      if (newSelection.has(voiceId)) {
        newSelection.delete(voiceId);
      } else {
        newSelection.add(voiceId);
      }
      return newSelection;
    });
  };

  const handleDeleteSelectedVoices = async () => {
    const token = localStorage.getItem('jwt');
    const idsToDelete = Array.from(selectedVoices);

    try {
      for (const id of idsToDelete) {
        await axios.delete(`${apiUrl}/user_crud/delete_voices/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }

      // Filter out deleted voices locally
      setVoices((prev) => prev.filter((voice) => !selectedVoices.has(voice.id)));
      setSelectedVoices(new Set());
      console.log('Selected voices deleted successfully');
    } catch (error) {
      console.error('Error deleting selected voices:', error.response?.data || error.message);
    }
  };

  const tabContent = [
    (
      <div className="voicesTab">
        {/* <div className="card">
          <h4><img src="/images/flag.svg" alt="flag" /> Get started with voices</h4>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
        </div> */}
        <div className="addVoice">
          {/* <div className="content">
            <h4>Your voices</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
          </div> */}
          <button className="add" onClick={() => setModalShow(true)}>Add voice</button>
          <button
              className="deleteSelected"
              onClick={handleDeleteSelectedVoices}
              disabled={selectedVoices.size === 0}
            >
              Delete Voice
            </button>
        </div>
        {voices.length > 0 ? (
          <div className="voicesList">
            <ul className="">
              {voices.map((voice) => (
                <li key={voice.id}  className="list">
                  <input
                  className="checkbox"
                    type="checkbox"
                    checked={selectedVoices.has(voice.id)}
                    onChange={() => handleCheckboxChange(voice.id)}
                  />
                  {voice.name}
                </li>
              ))}
            </ul>
            
          </div>
        ) : (
          <p className="info">You haven’t created any voices yet</p>
        )}
      </div>
    ),
  ];

  return (
    <div className="voice">
      <div className="title">
        <h4 style={{marginTop:'20px',marginLeft:'20px', fontSize:'25px',fontWeight:'600'}}>Brand voice</h4>
      </div>
      <div className="tabs">
        <div className="tab-content">
          {tabContent[tabs]}
        </div>
      </div>
      <VoiceModal show={modalShow} handleClose={() => setModalShow(false)} onAddVoice={handleAddVoice} />
    </div>
  );
}
