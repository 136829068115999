// Config.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, GithubAuthProvider, signInWithEmailAndPassword } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD0GDa3Nh1f6OJcgqK_dpuRY04n74ssdGM",
  authDomain: "chat-base-recommender-system.firebaseapp.com",
  projectId: "chat-base-recommender-system",
  storageBucket: "chat-base-recommender-system.appspot.com",
  messagingSenderId: "632583679869",
  appId: "1:632583679869:web:3b1680bc0e4bee1f472614",
  measurementId: "G-G1MY7H0ZEX"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const githubProvider = new GithubAuthProvider();













const handleSignInError = (errorCode) => {
  console.log('THAT ERROR INSIDE THE HANDLE SIGN-IN ERROR FUNCTION XXXX', errorCode);
  switch (errorCode) {
    case "auth/invalid-email":
      return "Invalid email format.";
    case "auth/user-disabled":
      return "User account has been disabled.";
    case "auth/user-not-found":
      return "No user found with this email.";
    case "auth/wrong-password":
      return "Incorrect password.";
    case "auth/too-many-requests":
      return "Too many requests. Please try again later.";
    case "auth/network-request-failed":
      return "Network error. Please check your connection.";
    case "auth/invalid-credential":
      return "Invalid credentials.";
    case "Request failed with status code 403":
      return "Your account is suspended. Please contact support.";  // Handling the 403 error
    default:
      return errorCode;
  }
};








const signInWithEmail = async (email, password) => {
  try {
    // Sign in the user
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
 
    return userCredential; // Return the entire userCredential object
  } catch (error) {
    console.log('Sign in error:', error);

    // Check if the error has a Firebase error code
    if (error.code) {
      const errorMessage = handleSignInError(error.code);
      console.log('Firebase error message:', errorMessage);
      throw new Error(errorMessage);
    }

    // Handle email verification error message or other unexpected errors
    if (error.message) {
      console.log('Error message:', error.message);
      throw new Error(error.message); // Throw email verification error as is
    }

    // Handle unexpected errors
    throw new Error('An unexpected error occurred.');
  }
};





// const microsoftProvider = new MicrosoftAuthProvider();

// Configure GitHub OAuth Provider


export { auth, googleProvider, facebookProvider, githubProvider, signInWithEmail, handleSignInError };
