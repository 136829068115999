

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import "./assets/scss/global.scss";
import "bootstrap/dist/css/bootstrap.min.css";
// import { AuthProvider } from "./context/AuthContext";
import { AuthProvider } from "./Context/AuthContext";

// Create the root element
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
